import { ShadCnButton } from '@/shadcn-ui/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/shadcn-ui/components/ui/dropdown-menu';
import { AlignJustify } from 'lucide-react';
import Link from 'next/link';

const solutions = [
  {
    title: 'Adstart Messages',
    description: 'Centralize toda sua operação no WhatsApp com um único chip',
    href: '/solutions/adstart-messages',
  },
];

export function MobileNav() {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <ShadCnButton size={'icon'} variant="outline" className="h-10 w-10">
          <AlignJustify className="w-5 h-5" />
        </ShadCnButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent side="bottom" align="start" className="w-56">
        <DropdownMenuGroup>
          <DropdownMenuLabel className="text-lg">Soluções</DropdownMenuLabel>
          {solutions.map(({ title, href }) => (
            <DropdownMenuItem key={title} className="ml-2">
              <Link href={href} passHref>
                <p className="text-lg">{title}</p>
              </Link>
            </DropdownMenuItem>
          ))}
          <DropdownMenuSeparator />
          <div className="flex justify-around">
            <Link href={'/'} locale="pt" passHref>
              <div className="cursor-pointer hover:bg-slate-100 p-1 px-2 rounded-md">
                <p className="text-2xl">🇧🇷</p>
              </div>
            </Link>
            <Link href={'/'} locale="en" passHref>
              <div className="cursor-pointer hover:bg-slate-100 p-1 px-2 rounded-md">
                <p className="text-2xl">🇺🇸</p>
              </div>
            </Link>
          </div>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
