interface UpperHeadingAndTitleProps {
  upperHeading: string;
  title: string;
}

export function UpperHeadingAndTitle({
  upperHeading,
  title,
}: UpperHeadingAndTitleProps) {
  return (
    <div className="flex flex-col gap-2 items-center">
      <h2 className="text-xl text-primary uppercase font-bold text-center">
        {upperHeading}
      </h2>
      <h1 className="md:text-[46px] text-4xl font-bold text-center text-gray-700 leading-tight">
        {title}
      </h1>
    </div>
  );
}
