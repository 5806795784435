type AvaialbleLanguages = 'pt' | 'en';

type TextsWithSupoortedLanguages<T> = {
  [key in AvaialbleLanguages]: T;
};

interface CreateTranslationsParams<T> {
  data: TextsWithSupoortedLanguages<T>;
  locale?: string;
}

export function createTranslations<T>({
  data,
  locale = 'pt',
}: CreateTranslationsParams<T>): T {
  if (locale === 'pt' || locale === 'en') {
    return data[locale];
  }

  return data.en;
}
