import { LanguageSelector } from '@/components/LanguageSelector';
import { ShadCnButton } from '@/shadcn-ui/components/ui/button';
import Link from 'next/link';
import { DesktopNav } from '../DesktopNav';
import { MobileNav } from '../MobileNav';

export function Header() {
  return (
    <header className="flex flex-col items-center fixed top-0 z-50 w-full border-b border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 font-poppins">
      <div className="px-4 flex justify-between items-center w-full max-w-7xl min-h-[60px]">
        <div className="md:hidden">
          <MobileNav />
        </div>
        <div className="flex items-center gap-12">
          <div className="cursor-pointer">
            <Link href={'/'} passHref>
              <img alt="Adstart logo" src="/logo3.svg" width="210px" />
            </Link>
          </div>
          <div className="hidden md:flex items-center gap-2">
            <DesktopNav />
          </div>
        </div>

        <div className="flex items-center gap-6">
          <div className="hidden md:block">
            <LanguageSelector />
          </div>
          <ShadCnButton variant="link" className="px-0">
            <Link href={'/login'} passHref>
              <a className="bg-gradient-to-r from-[#1C45D6] to-[#7903EF] text-transparent bg-clip-text text-lg font-semibold">
                Login
              </a>
            </Link>
          </ShadCnButton>
        </div>
      </div>
    </header>
  );
}
