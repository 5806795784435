import { Button, Collapse, Flex, Text, useDisclosure } from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { UrlObject } from 'url';

const availableLanguages = {
  en: '🇺🇸',
  pt: '🇧🇷',
} as const;

type SupportedLocale = keyof typeof availableLanguages;

interface LanguageSelectorProps {
  redirectTo?: string | UrlObject;
}

export function LanguageSelector({ redirectTo }: LanguageSelectorProps) {
  const router = useRouter();
  const { isOpen, onToggle, onClose } = useDisclosure();

  const currentFlag =
    availableLanguages[router.locale as SupportedLocale] ??
    availableLanguages.en;

  const redirectToUrl = redirectTo || router.pathname;

  return (
    <Flex flexDir="column" mr={4}>
      <Button
        onClick={onToggle}
        fontSize="24px"
        w="56px"
        bg="transparent"
        aria-label="Select language"
        aria-expanded={isOpen}
      >
        {currentFlag}
      </Button>

      <Collapse in={isOpen} animateOpacity>
        <Flex
          flexDir="column"
          position="absolute"
          alignItems="center"
          w="56px"
          bg="gray.100"
          borderBottomRadius={8}
          zIndex={1}
          mt={-1}
          role="menu"
        >
          {Object.entries(availableLanguages).map(([locale, flag]) => (
            <Link href={redirectToUrl} locale={locale} passHref key={locale}>
              <Text
                as="span"
                cursor="pointer"
                fontSize={20}
                w="100%"
                display="flex"
                justifyContent="center"
                _hover={{ bg: 'gray.200' }}
                onClick={onClose}
                role="menuitem"
              >
                {flag}
              </Text>
            </Link>
          ))}
        </Flex>
      </Collapse>
    </Flex>
  );
}
