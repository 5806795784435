import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from '@/shadcn-ui/components/ui/navigation-menu';
import { cn } from '@/shadcn-ui/lib/utils';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { translations } from './translations';

export function DesktopNav() {
  const { locale } = useRouter();
  const t = translations(locale);

  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger className="text-black">
            {t.solutions}
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="flex flex-col gap-3 p-6 w-[310px]">
              <Link href={'/solutions/adstart-messages'} passHref>
                <ListItem title={t.title}>{t.solutionDescription}</ListItem>
              </Link>
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <Link href="/customers" passHref>
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>
              {t.customers}
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
}

const ListItem = React.forwardRef<
  React.ElementRef<'a'>,
  React.ComponentPropsWithoutRef<'a'>
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            'block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
            className,
          )}
          {...props}
        >
          <div className="text-md font-semibold leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-secondary-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = 'ListItem';
