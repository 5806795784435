import { createTranslations } from '../../../utils/i18n';

interface Fields {
  general: string;
  free: string;
  login: string;
  talkToUs: string;
  terms: string;
  spreadsheet: string;
  copyright: string;
}

export const translations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        general: 'General',
        free: 'Free',
        login: 'Login',
        talkToUs: 'Contact',
        terms: 'Terms',
        spreadsheet: 'Free spreadsheet',
        copyright: `© ${new Date().getFullYear()} Adstart. All rights reserved`,
      },
      pt: {
        general: 'Geral',
        free: 'Grátis',
        login: 'Login',
        talkToUs: 'Fale conosco',
        terms: 'Termos',
        spreadsheet: 'Gerenciamento de anúncios',
        copyright: `© ${new Date().getFullYear()} Adstart. Todos os direitos reservados.`,
      },
    },
  });
