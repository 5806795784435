import { ShadCnButton } from '@/shadcn-ui/components/ui/button';
import Link from 'next/link';
import { UpperHeadingAndTitle } from '../UpperHeadingAndTitle';

interface KnowMoreProps {
  title: string;
  upperHeading: string;
  cta: string;
  trialLink: string;
}

export default function KnowMore({
  title,
  upperHeading,
  cta,
  trialLink,
}: KnowMoreProps) {
  return (
    <div className="flex flex-col items-center w-full max-w-7xl gap-8 py-12 font-poppins">
      <UpperHeadingAndTitle upperHeading={upperHeading} title={title} />
      <ShadCnButton
        variant={'adstart'}
        className={'rounded-full text-xl w-fit'}
      >
        <Link href={trialLink}>{cta}</Link>
      </ShadCnButton>
    </div>
  );
}
