import Footer from '../Footer';
import { Header } from '../Header';

interface BaseProps {
  children: React.ReactElement[];
}

export function Base({ children }: BaseProps) {
  return (
    <>
      <header>
        <Header />
      </header>

      <div className="w-full h-full pt-20 max-w-screen flex flex-col items-center overflow-x-hidden font-poppins">
        {children}
      </div>

      <Footer />
    </>
  );
}
