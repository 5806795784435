import { createTranslations } from '../../../utils/i18n';

interface Fields {
  ecommercePageNavItems: string;
  agenciesPageNavItems: string;
  solutions: string;
  productNavItems: string;
  subLabelEcommercePageNavItems: string;
  solutionDescription: string;
  subLabelAgenciesPageNavItems: string;
  freeTrial: string;
  customers: string;
  title: string;
}

export const translations = (locale?: string) =>
  createTranslations<Fields>({
    locale,
    data: {
      en: {
        ecommercePageNavItems: 'E-commerces',
        agenciesPageNavItems: 'Agencies',
        productNavItems: 'Home',
        solutions: 'Solutions',
        title: 'Adstart Messages',
        subLabelEcommercePageNavItems: 'Built for scalling your e-commerce',
        solutionDescription: 'Built for scalling your e-commerce',
        subLabelAgenciesPageNavItems: 'Built for hyper-growth agencies',
        freeTrial: 'Learn more',
        customers: 'Customers',
      },
      pt: {
        solutions: 'Soluções',
        title: 'Adstart Messages',
        ecommercePageNavItems: 'E-commerces',
        agenciesPageNavItems: 'Agências',
        productNavItems: 'Home',
        subLabelEcommercePageNavItems: 'Feito para escalar o seu negócio',
        solutionDescription:
          'Centralize toda sua operação no WhatsApp com um único chip',
        subLabelAgenciesPageNavItems: 'Feito para agências de alto crescimento',
        freeTrial: 'Saiba mais',
        customers: 'Clientes',
      },
    },
  });
